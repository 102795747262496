import { trackPromise } from "react-promise-tracker";
import BaseService, { returnPromise } from "../base-service";
import StandardResponse, { standardResponse } from "../standard-response.model";
import { IReportTag } from "../../types";
// import { IGetAccessTokenBody } from "../../LoginBypass";
// import axios from "axios";

export default class CommonService {
  getPermissions = async (): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `user/permission`,
        method: "get",
      });
      return standardResponse(res);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  GetSystemConfiguration = async (): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `/meter/dashboard/configuration`,
        method: "get",
      });
      return standardResponse(res);
    } catch (e) {
      return Promise.reject(e);
    }
  };



  GetUserProfile = async (): Promise<StandardResponse<any>> => {
    try {
      const res = await BaseService({
        url: `/user/profile/read`,
        method: "get",
      });
      return standardResponse(res);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  GetMeterTypes = async (): Promise<StandardResponse<any>> => {
    return trackPromise(returnPromise(`/meter/dashboard/meter-types`, "GET"));
  };

  getSite = async (): Promise<StandardResponse<any>> => {
    return trackPromise(returnPromise(`/meter/sites/all`, "GET"));
  };

  GetAllReportTags = async (): Promise<StandardResponse<IReportTag[]>> => {
    return trackPromise(returnPromise(`reports/get-all-tags`, "GET"));
  }

  // GetAccessToken = async (data: IGetAccessTokenBody): Promise<StandardResponse<any>> => {
  //   return trackPromise(axios.post("https://dev-ai-user-management.azurewebsites.net/api/GetAccessToken",data));
  // }
}
